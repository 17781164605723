/**********
*   LATO  *
**********/

@font-face {
  font-family: 'lato';
  src: url('./lato/lato-regular.woff2') format('woff2'),
  url('./lato/lato-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'lato';
  src: url('./lato/lato-bold.woff2') format('woff2'),
    url('./lato/lato-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/******************
*   COMMISSIONER  *
******************/

@font-face {
  font-family: 'commissioner';
  src: url('./commissioner/commissioner-light.woff2') format('woff2'),
    url('./commissioner/commissioner-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'commissioner';
  src: url('./commissioner/commissioner-regular.woff2') format('woff2'),
    url('./commissioner/commissioner-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'commissioner';
  src: url('./commissioner/commissioner-medium.woff2') format('woff2'),
    url('./commissioner/commissioner-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'commissioner';
  src: url('./commissioner/commissioner-bold.woff2') format('woff2'),
    url('./commissioner/commissioner-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/************
*  ARCHIA   *
************/

@font-face {
  font-family: 'archia';
  src: url('./archia/archia-thin.woff2') format('woff2'),
    url('./archia/archia-thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'archia';
  src: url('./archia/archia-regular.woff2') format('woff2'),
    url('./archia/archia-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'archia';
  src: url('./archia/archia-medium.woff2') format('woff2'),
    url('./archia/archia-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'archia';
  src: url('./archia/archia-bold.woff') format('woff2'),
    url('./archia/archia-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

